body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
 ::-webkit-scrollbar-thumb {
  background: #55555533;
  border-radius: 3px; 
}
::-webkit-scrollbar-thumb:hover {
  background: #55555577; 
}
body::-webkit-scrollbar {
  display: none;
} 
body {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
 } 

 body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
